<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
        <a-radio-group
          :default-value="tableViewMode.toString()"
          @change="viewModeChange"
        >
          <a-radio-button value="false">
            Mobile App Mode
          </a-radio-button>
          <a-radio-button value="true">
            Table App Mode
          </a-radio-button>
        </a-radio-group>
        <a-range-picker class="ml-2" style="width: 230px;" format="DD-MM-YYYY" :default-value="[moment().startOf('month'), moment().endOf('month')]" @change="rangedofunction"/>
        <!-- <a-date-picker
          class="ml-2"
                style="width: 125px;"
                v-model="tgl1"
                :allowClear="true"
                format="DD-MM-YYYY"
                @change="fetchData"
              /> -->
        <a-input class="ml-2" placeholder="Filter Rekening" style="width: 200px;" v-model="frekening" @input="fetchData"></a-input>
      </div>
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5" v-if="!tableViewMode">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div
              class="demo-infinite-container"
              :infinite-scroll-disabled="busy"
              :infinite-scroll-distance="10"
            >
              <div v-if="listRequest.length !== 0">
                <a-card
                  class="mt-2"
                  v-for="(element, index) in listRequest"
                  :key="index"
                  :style="
                    selectedIndex === index
                      ? 'cursor: pointer; background-color: #ACCEF7'
                      : 'cursor: pointer;'
                  "
                  @click="changeSelected(index)"
                >
                  <div id="body" style="padding: 12px 15px;">
                    <a-tag
                      class="mb-1"
                      :color="getColor(element.label)"
                      v-if="element.label !== '' && element.label !== null"
                      >{{ element.label }}</a-tag
                    >
                    <!-- <br /> -->
                    <strong style="font-size: 13px;"
                      >{{ element.name }} | Rp.
                      {{ formatCurrency(element.nominal) }}</strong
                    ><br />
                    <label style="font-size: 13px;"
                      >Nama: {{ element.nama }}</label
                    >
                    <label style="font-size: 13px;" v-if="element.label2 !== ''"
                      >, {{ element.label2 }}</label
                    >
                    <br />
                    <label style="font-size: 13px;"
                      >Rekening: {{ element.rekening }}</label
                    >
                    <br />
                    <label style="font-size: 13px;"
                      >User: {{ element.user }}</label
                    >
                    <label style="font-size: 13px;" class="float-right">{{
                      moment(element.tgl, "YYYY-MM-DDThh:mm:ss").format(
                        "DD-MM-YYYY HH:mm"
                      )
                    }}</label>
                  </div>
                </a-card>
              </div>
              <!-- <center style="margin-top: 9vh;" v-if="listRequest.length === 0">
                <mylottiecomp
                  :options="defaultOptions"
                  v-on:animCreated="handleAnimation"
                  height="50"
                  width="50"
                ></mylottiecomp>
                <strong>No data request!</strong>
              </center> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7" v-if="!tableViewMode">
        <a-button class="btn btn-block mb-2 btn-secondary" @click="getAllData"
          ><a-icon type="sync" class="mr-2" spin v-if="onloading" /> Refresh
          List Data Request</a-button
        >
        <a-card v-if="selectedIndex !== null">
          <div id="body" style="padding: 15px 20px;">
            <strong
              :style="
                rejectionMode
                  ? 'color: #FF0000;font-size: 15px;'
                  : 'color: #00AEAE;font-size: 15px;'
              "
              >{{
                rejectionMode ? "Confirm Rejection" : "Confirmation"
              }}</strong
            >
            <!-- <strong
              class="float-right"
              @click="rejectionMode = !rejectionMode"
              :style="
                rejectionMode
                  ? 'color: #00AEAE; font-size: 14px; cursor: pointer;'
                  : 'color: #FF0000;font-size: 14px; cursor: pointer;'
              "
              >{{
                rejectionMode ? "Change to Confirmation" : "Change to Rejection"
              }}</strong
            > -->
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <strong>Nomor Rekening</strong>
            <label class="float-right">{{ selectedData.rekening }}</label>
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <strong>Nama Nasabah</strong>
            <label class="float-right">{{ selectedData.nama }}</label>
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <strong>Nominal</strong>
            <label class="float-right">{{
              formatCurrency(selectedData.nominal)
            }}</label>
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <!-- <strong>Verified Status</strong>
            <a-tag
              class="float-right"
              :color="
                selectedData.verified == 0 || selectedData.verified == null
                  ? 'grey'
                  : selectedData.verified == 1
                  ? '#0096FF'
                  : '#FF0000'
              "
              >{{
                selectedData.verified == 0 || selectedData.verified == null
                  ? "Not Verified"
                  : selectedData.verified == 1
                  ? "Verified"
                  : "Rejected"
              }}</a-tag
            > -->
            <!-- <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            /> -->
            <strong>Verified By</strong>
            <label class="float-right">{{
              selectedData.verified_by === "" ||
              selectedData.verified_by === null
                ? ""
                : selectedData.verified_by
            }}</label>
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <!-- <strong>Approved Status</strong>
            <a-tag
              class="float-right"
              :color="
                selectedData.approved == 0 || selectedData.approved == null
                  ? 'grey'
                  : selectedData.approved == 1
                  ? '#00AEAE'
                  : '#FF0000'
              "
              >{{
                selectedData.approved == 0 || selectedData.approved == null
                  ? "Not Approved"
                  : selectedData.approved == 1
                  ? "Approved"
                  : "Rejected"
              }}</a-tag
            > -->
            <!-- <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            /> -->
            <strong>{{
              selectedData.label.charAt(0).toUpperCase() +
              selectedData.label.slice(1)
            }}</strong>
            <label class="float-right">{{
              selectedData.approved_by === "" ||
              selectedData.approved_by === null
                ? ""
                : selectedData.approved_by
            }}</label>
            <a-divider
              style="margin: unset; margin-top: 5px; margin-bottom: 5px;"
            />
            <div
              class="row mt-3"
              v-if="selectedData.can_do !== 0 && selectedData.approved === 0"
            >
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <a-button
                  class="btn btn-block btn-primary btn-danger"
                  @click="putReq(2, 'verify', selectedData.id)"
                  v-if="selectedData.can_do === 2"
                >
                  <a-icon type="close-circle" /> Reject
                </a-button>
                <a-button
                  class="btn btn-block btn-success btn-danger"
                  @click="putReq(2, 'approve', selectedData.id)"
                  v-if="
                    selectedData.can_do === 1 && selectedData.approved === 0
                  "
                >
                  <a-icon type="close" /> Reject
                </a-button>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <a-button
                  class="btn btn-block btn-primary"
                  @click="putReq(1, 'verify', selectedData.id)"
                  v-if="selectedData.can_do === 2"
                >
                  <a-icon type="check-circle" /> Verifiy
                </a-button>
                <a-button
                  class="btn btn-block btn-success"
                  @click="putReq(1, 'approve', selectedData.id)"
                  v-if="
                    selectedData.can_do === 1 && selectedData.approved === 0
                  "
                >
                  <a-icon type="check" /> Approve
                </a-button>
              </div>
            </div>
            <div v-else-if="selectedData.can_do === 0">
              <center>
                <strong>{{ selectedData.label2 }}</strong>
                <!-- <strong>Telah diputuskan atau anda tidak memiliki hak atas data ini.</strong> -->
              </center>
            </div>
          </div>
        </a-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-else>
        <a-card title="Confirmation Table View">
          <a-table
            style="margin-left: -15px; margin-right: -15px; margin-top: -14px;"
            :columns="columns"
            :dataSource="datatable"
            :scroll="{ x: 500 }"
            size="small"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                <span>
                  {{ text }}
                </span>
              </template>
            </template>
            <span slot="action" slot-scope="text, record">
              <a-dropdown v-if="record.can_do !== 0">
                <a-menu slot="overlay">
                  <a-menu-item
                    key="0"
                    class="text-secondary"
                    v-if="record.can_do === 0"
                  >
                    Tidak ada action yang dapat di lakukan
                  </a-menu-item>
                  <a-menu-item
                    key="1"
                    class="text-danger"
                    @click="putReq(2, 'verify', record.id)"
                    v-if="record.can_do === 2"
                  >
                    <a-icon type="close-circle" />Reject
                  </a-menu-item>
                  <a-menu-item
                    key="2"
                    class="text-danger"
                    @click="putReq(2, 'approve', record.id)"
                    v-if="record.can_do === 1 && record.approved === 0"
                  >
                    <a-icon type="close" />Reject
                  </a-menu-item>
                  <a-menu-item
                    key="3"
                    class="text-primary"
                    @click="putReq(1, 'verify', record.id)"
                    v-if="record.can_do === 2"
                  >
                    <a-icon type="check-circle" />Verifiy
                  </a-menu-item>
                  <a-menu-item
                    key="4"
                    class="text-success"
                    @click="putReq(1, 'approve', record.id)"
                    v-if="record.can_do === 1 && record.approved === 0"
                  >
                    <a-icon type="check" />Approve
                  </a-menu-item>
                </a-menu>
                <a-button><a-icon type="menu"></a-icon> Menu</a-button>
              </a-dropdown>
              <span v-else :class="record.label.includes('Approve') ? 'text-success' : record.label.includes('Cancel') ? 'text-danger' : 'text-warning'">{{ record.label }}</span>
            </span>
            <template slot="appList" slot-scope="text, record">
              <a-tag v-for="(data, index) in record.appLists" :key="index">{{
                data.appName
              }}</a-tag>
            </template>
            <template slot="id_Default_App" slot-scope="text, record">
              <a-tag color="blue">{{ findName(record) }}</a-tag>
            </template>
            <span slot="tanggal" slot-scope="text">
              {{
                moment(text, "YYYY-MM-DDThh:mm:ss").format("DD-MM-YYYY HH:mm")
              }}
            </span>
            <span slot="nominal" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
// import mylottiecomp from '@/components/lou/lottie'
import debounce from 'lodash/debounce'
import { Modal } from 'ant-design-vue'
import animationData from '@/assets/animations/25943-nodata.json'

export default {
  components: {
    // mylottiecomp,
  },
  created() {
    var initView = localStorage.getItem('tableViewOnCardConfirm')
    if (initView) {
      this.tableViewMode = initView === 'true'
    }
    this.getAllData()
  },
  data() {
    this.lastFetchId = 0
    this.fetchData = debounce(this.fetchData, 500)
    return {
      tgl1: moment().startOf('month').format('YYYY-MM-DD'),
      tgl2: moment().endOf('month').format('YYYY-MM-DD'),
      frekening: '',

      tableViewMode: false,
      defaultOptions: { animationData: animationData },
      rejectionMode: false,
      listRequest: [],
      backupListRequest: [],
      busy: false,
      onloading: false,
      componentKey: 0,
      selectedIndex: null,
      selectedData: {
        approved: 0,
        approved_by: '',
        bpr_id: '',
        id: '',
        nama: '',
        name: '',
        nominal: '',
        permission_id: '',
        rekening: '',
        tgl: '',
        type: '',
        user: '',
        verified: 0,
        verified_by: '',
      },

      // Table property
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      datatable: [],
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 110,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'CAN',
          dataIndex: 'can_do',
          width: 120,
          scopedSlots: {
            customRender: 'can_do',
          },
        },
        {
          title: 'tgl',
          dataIndex: 'tgl',
          width: 120,
          scopedSlots: {
            customRender: 'tanggal',
          },
        },
        {
          title: 'user',
          dataIndex: 'user',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.user.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'rekening',
          dataIndex: 'rekening',
          width: 180,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.rekening
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'nama',
          dataIndex: 'nama',
          width: 150,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nominal',
          dataIndex: 'nominal',
          width: 120,
          scopedSlots: {
            customRender: 'nominal',
          },
        },
        {
          title: 'Verified',
          dataIndex: 'verified_by',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.verified_by
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Approved',
          dataIndex: 'approved_by',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.approved_by
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tindakan',
          dataIndex: 'name',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Status',
          dataIndex: 'label',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.label.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Status',
          dataIndex: 'label2',
          width: 100,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.label2
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'notes',
          dataIndex: 'notes',
          width: 300,
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.notes.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
    }
  },

  methods: {
    moment,
    rangedofunction(date, dateString) {
      // console.log('date', date)
      if (date.length === 0) {
        this.tgl1 = null
        this.tgl2 = null
      } else {
        this.tgl1 = date[0].format('YYYY-MM-DD')
        this.tgl2 = date[1].format('YYYY-MM-DD')
      }
      this.getAllData()
    },
    fetchData() {
      this.getAllData()
      // console.log('this.tgl1', this.tgl1)
      // var data = []
      // // var nlistdata = []
      // if (this.tgl1 !== null && this.frekening !== '') {
      //   console.log('1')
      //   data = this.backupListRequest.filter(x => x.rekening.includes(this.frekening) && x.tgl.split('T')[0] === this.tgl1.format('YYYY-MM-DD'))
      //   // console.log('this.tgl1.format("YYYY-MM-DD")', this.tgl1.format('YYYY-MM-DD'))
      // } else
      // if (this.frekening !== '') {
      //   console.log('2')
      //   data = this.backupListRequest.filter(x => x.rekening.includes(this.frekening))
      // } else if (this.tgl1 !== null) {
      //   console.log('3')
      //   data = this.backupListRequest.filter(x => x.tgl.split('T')[0] === this.tgl1.format('YYYY-MM-DD'))
      // } else {
      //   console.log('4')
      //   data = this.$g.clone(this.backupListRequest)
      // }
      // console.log('data', data)
      // if (data.length === 0) {
      //   lou.shownotif('Info', 'Tidak ada data yang ditemukan!')
      // } else {
      //   this.listRequest = this.$g.clone(data)
      //   this.datatable = this.$g.clone(data)
      // }
    },
    viewModeChange(e) {
      // console.log('e.target.value', e.target.value)
      var val = e.target.value
      if (val === 'true') {
        this.tableViewMode = true
        localStorage.setItem('tableViewOnCardConfirm', val)
      } else {
        this.tableViewMode = false
        localStorage.setItem('tableViewOnCardConfirm', val)
      }
    },
    changeSelected(index) {
      this.selectedIndex = index
      if (this.listRequest[index].type === 1) {
        this.rejectionMode = false
      }
      this.selectedData = this.listRequest[index]
    },
    async getAllData() {
      this.onloading = true
      var imbuhan = ''
      imbuhan += this.tgl1 !== null ? '?tgl1=' + this.tgl1 : ''
      imbuhan += this.tgl2 !== null ? '&tgl2=' + this.tgl2 : ''
      imbuhan += this.tgl1 === null && this.tgl2 === null ? this.frekening !== '' ? '?rekening=' + this.frekening : '' : this.frekening !== '' ? '&rekening=' + this.frekening : ''
      var res = await lou.customUrlGet(
        'otorisasi/request/confirm' + imbuhan,
        false,
        true,
      )
      if (res) {
        this.listRequest = res.data
        this.backupListRequest = this.$g.clone(res.data)
        this.datatable = res.data
        this.onloading = false
      } else {
        this.onloading = false
      }
    },
    async putReq(rejectedMode, towhere, id) {
      // console.log('towhere', towhere)
      Modal.confirm({
        title: rejectedMode === 2 ? 'Reject, Yakin?' : lou.capitalize(towhere) + ', Yakin?',
        content: 'Apakah anda yakin ingin melanjutkan?',
        okText: 'Yes',
        okType: rejectedMode === 2 ? 'danger' : 'primary',
        cancelText: 'No',
        onOk: async () => {
          var data = {
            id: id,
            status: rejectedMode,
          }
          var res = await lou.customUrlPost(
            'otorisasi/request/confirm',
            data,
            true,
            true,
          )
          if (res) {
            this.componentKey += 1
            this.selectedIndex = null
            this.selectedData = {
              approved: 0,
              approved_by: '',
              bpr_id: '',
              id: '',
              nama: '',
              name: '',
              nominal: '',
              permission_id: '',
              rekening: '',
              tgl: '',
              type: '',
              user: '',
              verified: 0,
              verified_by: '',
            }
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleAnimation(anim) {
      this.anim = anim
    },

    stop() {
      this.anim.stop()
    },

    play() {
      this.anim.play()
    },

    pause() {
      this.anim.pause()
    },

    getColor(data) {
      var labelLoweredCase = data.toLowerCase()
      if (labelLoweredCase.includes('reject')) {
        return '#D62828'
      } else if (labelLoweredCase.includes('waiting')) {
        return '#F77F00'
      } else if (labelLoweredCase.includes('approve')) {
        return '#4DB6AC'
      } else if (labelLoweredCase.includes('cancel')) {
        return '#607D8B'
      } else {
        return '#ab47bc'
      }
    },

    onSpeedChange() {
      this.anim.setSpeed(this.animationSpeed)
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px;
  height: 62vh;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.demo-infinite-container1 {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px;
  max-height: 70vh;
}
.demo-loading-container1 {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>
